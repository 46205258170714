import { initDoubleClick, initMobileNav } from './header';
import { initFacilityMap, initFacilityCaro } from './facility';
import { initReviewsCaro } from './reviews';
import { contactSubmit } from './contact.js';
import { initHomeCaro, initHomeMap, initHomeFacMap } from './home';
import { initMultiMap } from './multiMap';

initMobileNav();

if(document.querySelector('.d_click')) {
	initDoubleClick();
}

if(document.querySelector('#facility_caro')) {
	initFacilityCaro();
}

if(document.querySelector('#facility_map')) {
	initFacilityMap();
}
// if(document.querySelector('#nearby_map')) {
// 	initNearbyMap();
// }

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'facility', 'message']
	});
}

if (document.getElementById('refer_form_wrapper')) {
	contactSubmit({
		elementSelector: '#refer_form',
		required: ['name', 'referred', 'unit', 'date', 'facility', 'address', 'city', 'state', 'zip']
	});
}

// Home carousel
if(document.querySelector('#home_caro')) {
	initHomeCaro();
}
// Map listing map
if(document.querySelector('#map_canvas')) {
	initHomeMap();
}

if(document.querySelector('#home_facs')) {
	initHomeFacMap();
}

if(document.querySelector('#multi_map')) {
	initMultiMap();
}

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}